<script setup lang="ts">
import type {PropType} from "vue";
import type {ElementorElementWidget} from "~/integration/wordpress/types";
import type {ComponentPublicInstance} from "@vue/runtime-core";

const props = defineProps({
  element: {
    type: Object as PropType<ElementorElementWidget>,
    default: {},
    required: true
  }
})

const components = {
  'heading': defineAsyncComponent(() => import('./Widgets/Heading.vue')),
  'image': defineAsyncComponent(() => import('./Widgets/Image.vue')),
  'text-editor': defineAsyncComponent(() => import('./Widgets/TextEditor.vue')),
  'button': defineAsyncComponent(() => import('./Widgets/Button.vue')),
  'html': defineAsyncComponent(() => import('./Widgets/Html.vue')),
  'MainBanner': defineAsyncComponent(() => import('./Widgets/Redmonkey/MainBanner.vue')),
  'Courses': defineAsyncComponent(() => import('./Widgets/Redmonkey/Courses.vue')),
  'Events': defineAsyncComponent(() => import('./Widgets/Redmonkey/Events.vue')),
  'WhyWe': defineAsyncComponent(() => import('./Widgets/Redmonkey/WhyWe.vue')),
  'AboutUs': defineAsyncComponent(() => import('./Widgets/Redmonkey/AboutUs.vue')),
  'Testimonials': defineAsyncComponent(() => import('./Widgets/Redmonkey/Testimonials.vue')),
  'Faq': defineAsyncComponent(() => import('./Widgets/Redmonkey/Faq.vue')),
  'SingleEvent': defineAsyncComponent(() => import('./Widgets/Redmonkey/SingleEvent.vue')),
  'SingleCourse': defineAsyncComponent(() => import('./Widgets/Redmonkey/SingleCourse.vue'))
} as {[key: string]: ComponentPublicInstance | unknown}

const getId = () => {
  return props?.element?.id;
}
</script>

<template>
  <div
      class="elementor-widget-container"
      :id="getId()"
      :key="`element-${element.id}-${props.element.widgetType}`"
  >
    <component
        v-if="components[props.element.widgetType]"
        :is="components[props.element.widgetType]"
        :element="element"
    />
  </div>
</template>